<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      class="wizard-vertical mb-3"
      :hide-buttons="false"
      :start-index="4"
    >
      <tab-content title="Garal">
      <FormGeral/>
      </tab-content>

      <tab-content title="Contato">
      <FormContato/>
      </tab-content>

      <tab-content title="Endereco">
      <FormEndereco/>
      </tab-content>

      <tab-content title="Fiscal">
      <FormFiscal/>
      </tab-content>

      <tab-content title="Qualidade">
            <FormQualidade/>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
} from 'bootstrap-vue'
import FormFiscal from './FornecedorWFDetalheFiscal.vue'
import FormGeral from './FornecedorWFDetalheGeral.vue'
import FormContato from './FornecedorWFDetalheContato.vue'
import FormEndereco from './FornecedorWFDetalheEndereco.vue'
import FormQualidade from './FornecedorWFDetalheQualidade.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    FormGeral,
    FormEndereco,
    FormContato,
    FormFiscal,
    FormQualidade,
  },
  data() {
    return {
    }
  },
  methods: {
    mounted() {
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
