<template>
         <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h4 class="mb-0">
              Contato
            </h4>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nome Contato"
              
            >

              <b-form-input
                id="v-nomecontato"
                placeholder="NOMECONTATO"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="E-mail"
              
            >
              <b-form-input
                id="v-email"
                placeholder="EMAIL"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Apelido"
              
            >
              <b-form-input
                id="v-apelido"
                placeholder="APELIDO"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Telefone"
              
            >
              <b-form-input
                id="v-telefone"
                placeholder="TELEFONE"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Telefone Residencial"
              
            >
              <b-form-input
                id="v-fax"
                placeholder="FAX"
              />
            </b-form-group>
          </b-col>
        </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
    }
  },
  methods: {
    mounted() {
    },
  },
}
</script>
