<template>
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h4 class="mb-0">
              Endereço
            </h4>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="CEP"
             
            >

              <b-form-input
                id="v-cep"
                placeholder="CEP"
              />
            </b-form-group>
          </b-col>

          <b-col md="7">
            <b-form-group
              label="Endereco"
              
            >
              <b-form-input
                id="v-codend"
                placeholder="CODEND"
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="Numero"
              
            >
              <b-form-input
                id="v-numend"
                placeholder="NUMEND"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Cidade"
              
            >
              <b-form-input
                id="v-codbai"
                placeholder="CODCID"
              />
            </b-form-group>
          </b-col>

          <b-col md="5">
            <b-form-group
              label="Bairro"
              
            >
              <b-form-input
                id="v-codbai"
                placeholder="CODBAI"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Complemento"
              
            >
              <b-form-input
                id="v-complemento"
                placeholder="COMPLEMENTO"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Região"
              
            >
              <b-form-input
                id="v-codreg"
                placeholder="CODREG"
              />
            </b-form-group>
          </b-col>
        </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
    }
  },
  methods: {
    mounted() {
    },
  },
}
</script>
