<template>
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h4 class="mb-0">
              Fiscal
            </h4>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Classificação ICMS"
              
            >
              <b-form-input
                id="v-classificms"
                placeholder="CLASSIFICMS"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Código Sit. Trib. IPI Entrada"
              
            >
              <b-form-input
                id="v-cstipient"
                placeholder="CSTIPIENT"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Código Sit. Trib. IPI Saída"
              
            >
              <b-form-input
                id="v-cstipisai"
                placeholder="CSTIPISAI"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Código Enq. Legal IPI Entrada"
              
            >
              <b-form-input
                id="v-codenqipient"
                placeholder="CODENQIPIENT"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Código Enq. Legal IPI Saída"
              
            >
              <b-form-input
                id="v-codenqipient"
                placeholder="CODENQIPIENT"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="CPF Prod. Rural"
              
            >
              <b-form-input
                id="v-cpfprodrural"
                placeholder="CPFPRODRURAL"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Conta Contábil"
              
            >
              <b-form-input
                id="v-codctactb"
                placeholder="CODCTACTB"
                required
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Conta Contábil 2"
              
            >
              <b-form-input
                id="v-codctactb2"
                placeholder="CODCTACTB2"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Conta Contábil 3"
              
            >
              <b-form-input
                id="v-codctactb3"
                placeholder="CODCTACTB3"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Conta Contábil 4"
              
            >
              <b-form-input
                id="v-codctactb4"
                placeholder="CODCTACTB4"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Indicador de Comercialização"
              
            >
              <b-form-input
                id="v-indcomercializacao"
                placeholder="INDCOMERCIALIZACAO"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Indicador de Aquisição"
              
            >
              <b-form-input
                id="v-indaquisicao"
                placeholder="INDAQUISICAO"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Código SUFRAMA"
              
            >
              <b-form-input
                id="v-codsuframa"
                placeholder="CODSUFRAMA"
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="Grupo ICMS"
              
            >
              <b-form-input
                id="v-grupoicms"
                placeholder="GRUPOICMS"
              />
            </b-form-group>
          </b-col>

          <b-col md="5">
            <b-form-group
              label="% a ser subtraído da alíquota do FUNRURAL/INSS"
              
            >
              <b-form-input
                id="v-percredinss"
                placeholder="PERCREDINSS"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Empresa"
              
            >
              <b-form-input
                id="v-codemp"
                placeholder="CODEMP"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Dt. Validação"
              
            >
              <b-form-input
                id="v-dtval"
                placeholder="DTVAL"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-checkbox
              id="v-temipi"
              name="v-temipi"
              value="S"
              unchecked-value="N"
              switch
            >
              Tem IPI
            </b-form-checkbox>
          </b-col>

          <b-col md="6">
            <b-form-checkbox
              id="v-ipiincicms"
              name="v-ipiincicms"
              value="S"
              unchecked-value="N"
              switch
            >
              IPI incide no cálculo do ICMS
            </b-form-checkbox>
          </b-col>

          <b-col md="6">
            <b-form-checkbox
              id="v-calcinss"
              name="v-calcinss"
              value="S"
              unchecked-value="N"
              switch
            >
              Calcula FUNRURAL/INSS
            </b-form-checkbox>
          </b-col>

          <b-col md="6">
            <b-form-checkbox
              id="v-descstiva"
              name="v-descstiva"
              value="S"
              unchecked-value="N"
              switch
            >
              Considera desconto no cálculo de ST por IVA
            </b-form-checkbox>
          </b-col>

          <b-col md="6">
            <b-form-checkbox
              id="v-simples"
              name="v-simples"
              value="S"
              unchecked-value="N"
              switch
            >
              Optante pelo Simples Nacional
            </b-form-checkbox>
          </b-col>

          <b-col md="6">
            <b-form-checkbox
              id="v-produtortemnf"
              name="v-produtortemnf"
              value="S"
              unchecked-value="N"
              switch
            >
              Produtor tem NF
            </b-form-checkbox>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Observação"
              
            >
              <b-form-input
                id="v-observacao"
                placeholder="OBSERVACAO"
              />
            </b-form-group>
          </b-col>
        </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  data() {
    return {
    }
  },
  methods: {
    mounted() {
    },
  },
}
</script>
