<template>
<div>
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h4 class="mb-0">
              Geral
            </h4>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="Cód. Parceiro"
              
            >

              <b-form-input
                id="v-codparc"
                placeholder="CODPARC"
              />
            </b-form-group>
          </b-col>

          <b-col md="10">
            <b-form-group
              label="Nome Parceiro"
              
            >

              <b-form-input
                id="v-nomeparc"
                placeholder="NOMEPARC"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="CPJ / CPF"
              
            >
              <b-form-input
                id="v-cgc-cpf"
                placeholder="CGC_CPF"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Razão Social"
              
            >
              <b-form-input
                id="v-razaosocial"
                placeholder="RAZAOSOCIAL"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Insc. Estadual"
              
            >
              <b-form-input
                id="v-incestad"
                placeholder="IDENTINSCESTAD"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Perfil Principal"
              
            >
            </b-form-group>
          </b-col>

        </b-row>

        <b-form-checkbox
          id="v-fornecedor"
          name="v-fornecedor"
          value="S"
          unchecked-value="N"
          switch
        >
          Fornecedor
        </b-form-checkbox>
</div>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  data() {
    return {
    }
  },
  methods: {
    mounted() {
    },
  },
}
</script>
