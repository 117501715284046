<template>
<!-- Início aba Qualidade -->
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h4 class="mb-0">
              Qualidade
            </h4>
          </b-col>
        </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
    }
  },
  methods: {
    mounted() {
    },
  },
}
</script>
